import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useState } from "react";
import Cookies from "js-cookie";

export default function Navbar({ onSetNavSelection, onHandleLogout }) {
  const [selection, setSelection] = useState("currentProduction");
  const navRef = useRef();
  const selectedStyle = {
    color: "rgba(255, 255, 255, 0.645)",
  };

  function handleClick(e, val) {
    setSelection(val);
    onSetNavSelection(val);
    showNavbar();
  }

  function handleLogout() {
    Cookies.remove("Token");
    showNavbar();
    onHandleLogout();
  }

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <header>
      <h3>Simple ERP</h3>
      <nav ref={navRef}>
        <a
          style={selection === "currentProduction" ? selectedStyle : null}
          href="/#"
          onClick={(e, val) => handleClick(e, "currentProduction")}
        >
          Produkcja
        </a>
        <a
          style={selection === "presence" ? selectedStyle : null}
          href="/#"
          onClick={(e, val) => handleClick(e, "presence")}
        >
          Obecność
        </a>
        <a href="/#" onClick={handleLogout}>
          Wyloguj
        </a>
        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}
