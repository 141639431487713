export const COLUMNSPRODDETAILS = [
  {
    Header: "Nazwa",
    accessor: "productName",
  },
  {
    Header: "Maszyna",
    accessor: "machine",
  },
  {
    Header: "Proces",
    accessor: "process",
  },
  {
    Header: "Ilość",
    accessor: "amount",
  },
  {
    Header: "Data",
    accessor: "timestamp",
  },
];
