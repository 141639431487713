import React, { useEffect, useState } from "react";
import ProductionDetails from "./ProductionDetails";
import CurrentProductionList from "./CurrentProductionList";
import Cookies from "js-cookie";
import { backendUrl } from "../settings";

export default function CurrentProductionRouter({ token }) {
  const [detailedProductionId, setDetailedProductionId] = useState(null);

  function handleSetDetailedProductionId(id) {
    setDetailedProductionId(id);
  }

  if (detailedProductionId == null) {
    return (
      <CurrentProduction
        token={token}
        onSetDetailedProductionId={handleSetDetailedProductionId}
      />
    );
  } else {
    return (
      <ProductionDetails
        token={token}
        productionId={detailedProductionId}
        onSetDetailedProductionId={handleSetDetailedProductionId}
      />
    );
  }
}

function CurrentProduction({ token, onSetDetailedProductionId }) {
  const [orderIdFilter, setOrderIdFilter] = useState(null);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  function handleSetOrderIdFilter(id) {
    if (!parseInt(id)) return;
    setOrderIdFilter(id);
    filterData(id);
  }

  useEffect(() => {
    if (data.length === 0) {
      fetchData();
    }
  });

  const fetchData = async () => {
    try {
      const dataUrl = backendUrl + "production";
      const response = await fetch(dataUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Cookies.get("Token")}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      const dataWithKey = addRandomKey(jsonData);
      setData(dataWithKey);
      setFilteredData(dataWithKey);
    } catch (error) {
      console.log(error);
    }
  };

  function filterData(id) {
    const filteredData = [...data].filter((item) => {
      return item.id === id;
    });
    setFilteredData(filteredData);
  }

  function removeFilter() {
    setFilteredData(data);
  }

  function addRandomKey(data) {
    let key = 1;
    data.forEach((item) => {
      // Adding a new key-value pair to each object
      item.key = key;
      key += 1;
    });
    return data;
  }

  return (
    <div className="list-view">
      <h1>Aktualna produkcja</h1>
      <Form
        onSetOrderId={handleSetOrderIdFilter}
        onRemoveFilter={removeFilter}
      />
      <CurrentProductionList
        items={filteredData}
        onSetDetailedProductionId={onSetDetailedProductionId}
      />
    </div>
  );
}

function Form({ onSetOrderId, onRemoveFilter }) {
  const [filteredId, setFilteredId] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    onSetOrderId(parseInt(filteredId));
  }

  function handleDiscard(e) {
    e.preventDefault();
    onRemoveFilter();
    setFilteredId("");
  }

  return (
    <div className="container">
      <form
        className="filter-form"
        onSubmit={(e) => {
          const buttonName = e.nativeEvent.submitter.name;
          if (buttonName === "discard") handleDiscard(e);
          if (buttonName === "apply") handleSubmit(e);
        }}
      >
        <input
          type="text"
          placeholder="Nr. zamowienia"
          value={filteredId}
          onChange={(e) => setFilteredId(e.target.value)}
        ></input>
        <div>
          <button className="filter-apply-button" name="apply">
            APPLY
          </button>
          <button className="filter-discard-button" name="discard">
            X
          </button>
        </div>
      </form>
    </div>
  );
}
