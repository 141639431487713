import React from "react";
import { useRef } from "react";
import Cookies from "js-cookie";
import axios from "axios";

import "../Styles/index.css";
import { backendUrl } from "../settings";

export default function Login({ onSetIsLogged }) {
  const loginRef = useRef(null);
  // TEST LOGIN

  function handleLogin(e) {
    e.preventDefault();

    axios
      .get(backendUrl + "login", {
        headers: {
          Authorization: `Bearer ${loginRef.current.value}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          Cookies.set("Token", loginRef.current.value, { expires: 14 });
          onSetIsLogged(true);
        }
      })
      .catch((error) => alert(error.message));
  }

  return (
    <div className="login-box">
      <form onSubmit={handleLogin}>
        <input ref={loginRef} type="password" placeholder="hasło.." />
        <div>
          <button>Login</button>
        </div>
      </form>
    </div>
  );
}
