import React, { useState } from "react";
import CurrentProduction from "./Components/CurrentProduction";
import Login from "./Components/Login";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Cookies from "js-cookie";

export default function App() {
  const token = Cookies.get("Token");
  const loggedIn = token ? token : false;
  const [isLogged, setIsLogged] = useState(loggedIn);
  const [navSelection, setNavSelection] = useState("currentProduction");

  function handleSetNavSelection(selected) {
    setNavSelection(selected);
  }

  function handleSetIsLogged() {
    setIsLogged(true);
  }

  function handleLogout() {
    setIsLogged(false);
  }

  function displayPage() {
    if (navSelection === "currentProduction") {
      return <CurrentProduction token={token} />;
    } else if (navSelection === "presence") {
      return <h1>Lista obecności - strona w trakcie budowy</h1>;
    } else if (navSelection === "packaging") {
      return <h1>Lista pakowania - strona w trakcie budowy</h1>;
    }
  }

  return (
    <div className="app">
      {isLogged ? (
        <Navbar
          onSetNavSelection={handleSetNavSelection}
          onHandleLogout={handleLogout}
        />
      ) : (
        <Login onSetIsLogged={handleSetIsLogged} />
      )}
      {/* Coś tu poniżej mi nie pasuje... muszę sprawdzić też czy osoba jest zalogowana */}
      {isLogged && displayPage()}
      <Footer />
    </div>
  );
}

//
