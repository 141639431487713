import React, { useState, useEffect } from "react";
import axios from "axios";
import CurrentProductionDetailedList from "./CurrentProductionDetailedList";
import { backendUrl } from "../settings";

export default function ProductionDetails({
  token,
  productionId,
  onSetDetailedProductionId,
}) {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(backendUrl + `production\\${productionId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data);
        }
      })
      .catch((error) => alert(error.message));
  }, []);

  if (data.length !== 0) {
    return (
      <div className="list-view">
        <h1>Zamówienie nr. {productionId}</h1>
        <div className="container">
          <button
            onClick={() => onSetDetailedProductionId(null)}
            className="back-button"
          >
            Cofnij
          </button>
        </div>
        <CurrentProductionDetailedList items={data} />
      </div>
    );
  }
}
