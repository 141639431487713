export default function CurrentProductionList({
  items,
  onSetDetailedProductionId,
}) {
  return (
    <div className="production-list">
      <ul>
        {items.map((item) => (
          <Item
            item={item}
            key={item.key}
            onSetDetailedProductionId={onSetDetailedProductionId}
          />
        ))}
      </ul>
    </div>
  );
}

function Item({ item, onSetDetailedProductionId }) {
  return (
    <li>
      <button
        onClick={() => onSetDetailedProductionId(item.id)}
        className="production-list-item"
      >
        <h2>Zamówienie Nr. {item.id} </h2>
        <p>{item.productName}</p>
        <p>
          {item.processName} : {item.sum}
        </p>
        <p>{item.timestamp}</p>
      </button>
    </li>
  );
}
